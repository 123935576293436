import React, { useContext } from 'react';
import './WebDeveloperCV.css';
import { Row, Col } from 'react-bootstrap';
import BasicInfo from '../../Components/CvBuildingComponents/BasicInfo';
import CVimage from '../../Components/CvBuildingComponents/CVimage';
import ContactInfo from '../../Components/CvBuildingComponents/ContactInfo';
import WorkExperience from '../../Components/CvBuildingComponents/WorkExperience';
import Skills from '../../Components/CvBuildingComponents/Skills';
import Achievements from '../../Components/CvBuildingComponents/Achievmenets&Certificates';
import EditAndDownloadButtons from '../../Components/CvBuildingComponents/EditAndDownloadButtons';
import { ResumeContext } from '../../Context/ResumeContext';
import Education from '../../Components/CvBuildingComponents/Education';
import Languages from '../../Components/CvBuildingComponents/Languages';
import Interests from '../../Components/CvBuildingComponents/Interests';


const WebDeveloperCV = () => {
    const { isEditMode, activeFields } = useContext(ResumeContext);


    return (
        <div className='CV'>
            <EditAndDownloadButtons />
            <div className='WebDeveloperCV' id='downloadthis'>
                <Row className=''>
                    <Col className='WebDeveloperCV-basicinfo' md={7}>
                        <Row>
                            <Col md={9}>
                                <BasicInfo />
                            </Col>
                            <Col md={3}>
                                <CVimage />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        {activeFields.contactInfo && <ContactInfo />}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={6}>
                        {activeFields.workExperience && <WorkExperience />}
                        {(activeFields.education && isEditMode) && <Education />}
                    </Col>
                    <Col md={6}>
                        {activeFields.skills && <Skills />}
                        {activeFields.achievements && <Achievements />}
                        {isEditMode && <Languages />}
                        {!isEditMode && <Interests />}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default WebDeveloperCV;