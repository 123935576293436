import React,{useState} from 'react';
import AutosizeInput from 'react-input-autosize';
import AddNewButton from './AddNewButton';
import RemoveButton from './RemoveButton';

function SkillInput(props) {
    const [skillFocused, setSkillFocused] = useState(false);

    return (
        <span style={{ position: 'relative' }}>
            <AutosizeInput
                onChange={props.function}
                value={props.value}
                type="text"
                onClick={props.tips}
                onFocus={() => setSkillFocused(true)}
                onBlur={() => setSkillFocused(false)}
                data-tips='skills'
                disabled={props.disabled}
            />
            {skillFocused && <>
                <AddNewButton text='Add new Skill' function={props.add} />
                <RemoveButton text='Delete this skill' function={props.delete} />
            </>}
        </span>)
}
export default SkillInput;