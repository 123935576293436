import React,{useState, useContext, Fragment} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ResumeContext } from '../../Context/ResumeContext';
import AddNewButton from './AddNewButton';
import RemoveButton from './RemoveButton';


function AchievementInput(props) {
    const { achievementsChangeHandler, filterTipsToDisplay, isEditMode, addAchievement, deleteAchievement, deleteAchievementDescription } = useContext(ResumeContext);
    const [focused, setFocused] = useState({
        achievement: false,
        description: false
    })

    return (
        <Fragment>
            <TextareaAutosize
                value={props.value.achievement}
                onChange={(e) => achievementsChangeHandler(e, props.i)}
                onClick={(e) => filterTipsToDisplay(e)}
                onFocus={() => setFocused({ ...focused, achievement: true })}
                onBlur={() => setFocused({ ...focused, achievement: false })}
                className='achievement'
                placeholder='Achievement'
                name='achievement'
                data-tips='projects'
                disabled={!isEditMode}
            />
            {focused.achievement && <>
                <AddNewButton text=' Add new Achievement' function={() => addAchievement()} />
                <RemoveButton text='Delete this Achievement' function={() => deleteAchievement(props.i)} /> </>}
            {(props.value.description || focused.description) &&
                <span style={{ position: 'relative' }}>
                    <TextareaAutosize
                        value={props.value.description}
                        onChange={(e) => achievementsChangeHandler(e, props.i)}
                        onClick={(e) => filterTipsToDisplay(e)}
                        onFocus={() => setFocused({ ...focused, description: true })}
                        onBlur={() => setFocused({ ...focused, description: false })}
                        className='achievment-description'
                        placeholder='Achievement description'
                        name='description'
                        data-tips='projects'
                        disabled={!isEditMode}
                    />
                    {focused.description &&
                        <RemoveButton text='Delete this Achievement description' function={() => deleteAchievementDescription(props.i)} />
                    }
                </span>}
        </Fragment>
    )
}
export default AchievementInput;