import React, { useContext } from 'react';
import RatingComponent from './RatingComponent';
import { ResumeContext } from '../../Context/ResumeContext';

const SkillsDataScience = () => {
    const { dataScienceSkills, dataScienceSkillsChangeHandler, isEditMode } = useContext(ResumeContext);
    return (

        <div className='SkillsDataScience'>
            <input type="text" readOnly value="SKILLS" className='h3' />
            {dataScienceSkills &&
                dataScienceSkills.map((element, i) => (
                    <div className='dataScienceSkills' key={i}>
                        <input onChange={() => dataScienceSkillsChangeHandler()} type="text" value={element.skill} disabled={!isEditMode} />
                        <RatingComponent name={element.skill} value={element.level} handler={dataScienceSkillsChangeHandler} editmode={isEditMode}/>
                    </div>))}
        </div>
    )
}
export default SkillsDataScience;