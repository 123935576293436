import React from 'react';
import StarRatingComponent from 'react-star-rating-controlled-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const RatingComponent =(props)=>{

    return(
        <StarRatingComponent 
        editing={props.editmode} 
        name={props.name}
        value={props.value}
        onStarClick={(e)=>props.handler(e, props.name)}
        renderStarIcon={() => <span><FontAwesomeIcon icon={['fas', 'circle']} /></span>}
        starColor='rgb(44, 44, 44)'
        emptyStarColor='white'
        />
    )
}

export default RatingComponent;
