import React, { useContext, useMemo } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import SkillInput from './Skillinput';


const Skills = () => {
    const { skills, skillsChangehandler, filterTipsToDisplay, isEditMode, addSkill, deleteSkill } = useContext(ResumeContext);



    return useMemo(() => {
        return (
            <div className='Skills'>
                <input type="text" readOnly value="SKILLS & COMPETENCIES" className='h3' />
                {skills &&
                    skills.map((skill, i) =>
                        <SkillInput key={i}
                            function={(e) => skillsChangehandler(e, i)}
                            value = {skill}
                            tips = {(e) => filterTipsToDisplay(e)}
                            disabled={!isEditMode}
                            add={() => addSkill()}
                            delete = {() => deleteSkill(i)}
                        />
                        )}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, isEditMode])
}

export default Skills;