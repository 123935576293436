import React, { useState, useContext, useMemo } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import AddNewButton from './AddNewButton';
import RemoveButton from './RemoveButton';
import AchievementInput from './AchievementsInput';


const Achievements = () => {
    const { achievements, isEditMode, hideEntireSection, addAchievement } = useContext(ResumeContext);
    const [focused, setFocused] = useState(false);

    return useMemo(() => {
        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <input
                        type="text"
                        readOnly
                        value="ACHIEVEMENTS & CERTIFICATES"
                        className='h3 achievh3'
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                    />
                    {focused && <>
                        <AddNewButton text='Add new Achievement' function={() => addAchievement()} />
                        <RemoveButton text='Delete entire Achievements section' function={() => hideEntireSection('achievements')} />
                    </>}
                </div>
                <ul className='Achievements'>
                    {achievements &&
                        achievements.map((element, i) => (
                            <li key={i} style={{ position: 'relative' }}>
                                <AchievementInput 
                                    key={i}
                                    value={element}
                                    i={i}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievements, isEditMode, focused])
}

export default Achievements;