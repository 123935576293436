import React, { createContext, useState } from 'react';
import allData from '../db';
import uuid from 'uuid';
import html2pdf from 'html2pdf.js';


const Context = createContext({});

const Provider = (props) => {
    const [basicInfo, setBasicInfo] = useState();
    const [image, setImage] = useState();
    const [contactInfo, setContactInfo] = useState();
    const [workExperience, setWorkExperience] = useState();
    const [skills, setSkills] = useState();
    const [achievements, setAchievements] = useState();
    const [education, setEducation] = useState();
    const [dataScienceSkills, setDataScienceSkills] = useState();
    const [languages, setLanguages] = useState();
    const [informalEducation, setInformalEducation] = useState();
    const [selectedCategoryAllTips, setSelectedCategoryAllTips] = useState();
    const [chosenResumeTips, setChosenResumeTips] = useState();
    const [tipsToDisplay, setTipsToDisplay] = useState('In this field you will get hints and tips how to fill different sections of your resume');
    const [isEditMode, setIsEditMode] = useState(false);
    const [activeFields, setActiveFields] = useState({
        workExperience: true,
        skills: true,
        contactInfo: true,
        achievements: true,
        education: true,
        informalEducation: true,
    });
    const [cvFilled, setCvFilled] = useState([
        { basicInfoFilled: false },
        { contactInfoFilled: false },
        { workExperienceFilled: false },
        { educationFilled: false },
        { skillsFilled: false },
        { languagesFilled: false }
    ])
    const [fieldsFilled, setFieldFilled] = useState();
    const [laikaAndLinkedinChecked, setLaikaAndLinkedinChecked] = useState({
        laika: false,
        linkedin: false
    })


    const PdfDownloadCheck = () => {
        function basicinfocheck() {
            if (basicInfo.name.length > 0 && basicInfo.titles.length > 0) {
                setCvFilled([...cvFilled, cvFilled[0].basicInfoFilled = true]);
            }
        }
        function contactInfocheck() {
            if (contactInfo.mail !== '') {
                setCvFilled([...cvFilled, cvFilled[1].contactInfoFilled = true]);
            }
        }
        function workExperiencecheck() {
            if (workExperience.length > -1 && workExperience[0].position.length > 0) {
                setCvFilled([...cvFilled, cvFilled[2].workExperienceFilled = true]);
            }
        }
        function educationcheck() {
            if (education.length > -1 && education[0].study.length > 0) {
                setCvFilled([...cvFilled, cvFilled[3].educationFilled = true]);
            }
        }
        function skillscheck() {
            if (skills.length > 2) {
                setCvFilled([...cvFilled, cvFilled[4].skillsFilled = true]);
            }
        }
        function languagescheck() {
            if (languages.length > -1) {
                setCvFilled([...cvFilled, cvFilled[5].languagesFilled = true]);
            }
        }
        basicinfocheck();
        contactInfocheck();
        workExperiencecheck();
        educationcheck();
        skillscheck();
        languagescheck();
        let fields = 0;
        cvFilled.forEach(el => {
            Object.entries(el).forEach(([key, value]) => {
                if (value === true) {
                    fields++
                }
            })
        })
        setFieldFilled(fields)
    }

    const filterCategory = (e) => {
        let filtered = allData.filter(category => category.name === e.target.id)[0];
        setBasicInfo(filtered.CV.basicInfo);
        setImage(filtered.CV.image);
        setContactInfo(filtered.CV.contactInfo);
        setWorkExperience(filtered.CV.workExperience);
        setSkills(filtered.CV.skills);
        setInformalEducation(filtered.CV.informalEducation)
        setAchievements(filtered.CV.achievements);
        setEducation(filtered.CV.education);
        setDataScienceSkills(filtered.CV.dataScienceSkills);
        setLanguages(filtered.CV.languages);
        setSelectedCategoryAllTips(filtered.tips);
    }
    const filterTips = (e) => {
        let chosenResume = e.target.dataset.path
        const chosen = selectedCategoryAllTips.filter(el => el.name === chosenResume)[0];
        setChosenResumeTips(chosen)
        if (chosenResume === 'CV') {
            setTipsToDisplay(chosen.start)
        } else if (chosenResume === 'Linkedin') {
            setLaikaAndLinkedinChecked({ ...laikaAndLinkedinChecked, linkedin: true })
            setTipsToDisplay('Move your mouse over different parts of the image to get instructions about that specific section')
        } else if (chosenResume === 'Laika') {
            setLaikaAndLinkedinChecked({ ...laikaAndLinkedinChecked, laika: true })
            setTipsToDisplay('Move your mouse over different parts of the image to get instructions about that specific section')
        }
    }
    const filterTipsToDisplay = (e) => {
        const section = e.target.dataset.tips;
        setTipsToDisplay(chosenResumeTips[section]);

    }

    const basicInfoChangeHandler = (e) => {
        const { name, value } = e.target;
        setBasicInfo({ ...basicInfo, [name]: value })
    }
    const contactInfoChangeHandler = (e) => {
        const { name, value } = e.target;
        setContactInfo({ ...contactInfo, [name]: value })
    }
    const changeImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    }
    const workExperienceChangeHandler = (e, workExperienceId) => {
        const { name, value, id } = e.target;
        let workExperienceEditing = workExperience.findIndex(element => element.id === workExperienceId);
        let updated = JSON.parse(JSON.stringify(workExperience));
        if (name === 'position' || name === 'company') {
            updated[workExperienceEditing] = { ...updated[workExperienceEditing], [name]: value }
        } else if (name === 'startMonth' || name === 'startYear' || name === 'endMonth' || name === 'endYear' || name === 'location') {
            updated[workExperienceEditing].period = { ...updated[workExperienceEditing].period, [name]: value }
        } else if (name === 'jobDescription') {
            updated[workExperienceEditing].jobDescription[id] = value;
        } else if (name === 'aboutJob') {
            updated[workExperienceEditing].aboutJob = value;
        } else if (name === 'present') {
            updated[workExperienceEditing].period.present = !updated[workExperienceEditing].period.present;
        }
        setWorkExperience(updated);
    }
    const skillsChangehandler = (e, index) => {
        let updatedSkills = skills.slice();
        updatedSkills[index] = e.target.value;
        setSkills(updatedSkills);
    }
    const achievementsChangeHandler = (e, i) => {
        const { name, value } = e.target;
        const updatedAchievements = achievements.slice();
        updatedAchievements[i][name] = value;
        setAchievements(updatedAchievements);
    }
    const educationChangeHandler = (e, id, index) => {
        const { name, value } = e.target;
        let educationEditing = education.findIndex(element => element.id === id);
        const updatedEducation = education.slice();
        if (name === 'study' || name === 'institution') {
            updatedEducation[educationEditing] = { ...updatedEducation[educationEditing], [name]: value }
        } else if (name === 'startMonth' || name === 'startYear' || name === 'endMonth' || name === 'endYear' || name === 'location') {
            updatedEducation[educationEditing].period = { ...updatedEducation[educationEditing].period, [name]: value }
        } else if (name === 'present') {
            updatedEducation[educationEditing].period.present = !updatedEducation[educationEditing].period.present;
        } else if (name === 'educationDescription') {
            updatedEducation[educationEditing].educationDescription[index] = value;
        } else if (name === 'aboutEducation') {
            updatedEducation[educationEditing].aboutEducation = value;
        }
        setEducation(updatedEducation)
    }
    const dataScienceSkillsChangeHandler = (e, name) => {
        let updated = dataScienceSkills.slice();
        let index = updated.findIndex(element => element.skill === name);
        updated[index].level = e;
        setDataScienceSkills(updated);
    }
    const languageChangeHandler = (e, i) => {
        const { name, value, className } = e.target;
        let updated = languages.slice()
        if (name === 'language') {
            updated[i].language = value;
        } else {
            updated[i].level = Number(className);
        }
        setLanguages(updated)
    }
    const informalEducationChangehandler = (e, index) => {
        let updatedInformalEducation = informalEducation.slice();
        updatedInformalEducation[index] = e.target.value;
        setInformalEducation(updatedInformalEducation);
    }
    function editMode() {
        setIsEditMode(true);
        setCvFilled([{ basicInfoFilled: false },
        { contactInfoFilled: false },
        { workExperienceFilled: false },
        { educationFilled: false },
        { skillsFilled: false },
        { languagesFilled: false }]);
        setActiveFields({
            workExperience: true,
            skills: true,
            contactInfo: true,
            achievements: true,
            education: true,
            informalEducation: true
        })
        setBasicInfo({ name: '', titles: '', goals: '' });
        setImage(require(`../assets/images/CVimages/addimg.png`));
        setContactInfo({ mail: '', phone: '', location: '', twitter: '', linkedin: '' });
        setWorkExperience([{ id: uuid(), position: '', company: '', period: { startMonth: '00', startYear: '0000', endMonth: '11', endYear: '1111', present: false, location: '' }, aboutJob: 'Accomplishments/Tasks/Duties (Optional)', jobDescription: ['Accomplishment 1', 'Task 1', 'Task 2'] }])
        setSkills(['Skill 1', 'Skill 2', 'Another Skill', 'One More'])
        setAchievements([{ achievement: '', description: 'If needed write here description about achievment' }, { achievement: '', description: 'If needed write here description about achievment' }])
        setEducation([{ id: uuid(), study: '', institution: '', period: { startMonth: 'mm', startYear: 'year', endMonth: 'mm', endYear: 'year', present: false, location: '' }, aboutEducation: 'Studied/Courses', educationDescription: ['Thing Learned', 'Thing Learned 2', 'Thing Learned 3'] }])
        setInformalEducation(['Course', 'Webinar', 'Book']);
        setLanguages([{ language: 'English', level: 3 }, { language: '', level: 2 }])
    }
    function DeleteWorkAccomplishment(id, index) {
        let workExperienceEditing = workExperience.findIndex(element => element.id === id);
        let updated = workExperience.slice()
        updated[workExperienceEditing].jobDescription.splice(index, 1);
        setWorkExperience(updated)
    }
    function AddWorkAccomplishmentHandler(id) {
        let workExperienceEditing = workExperience.findIndex(element => element.id === id);
        let updated = workExperience.slice();
        let newItem = 'Added Accomplishment'
        updated[workExperienceEditing].jobDescription.push(newItem);
        setWorkExperience(updated)
    }
    function DeleteAboutJob(id) {
        let workExperienceEditing = workExperience.findIndex(element => element.id === id);
        let updated = workExperience.slice();
        updated[workExperienceEditing].aboutJob = '';
        setWorkExperience(updated);
    }
    function addWorkExperience() {
        let updated = workExperience.slice();
        const toAdd = { id: uuid(), position: '', company: '', period: { startMonth: '00', startYear: '0000', endMonth: '11', endYear: '1111', present: false, location: '' }, aboutJob: 'Accomplishments/Tasks/Duties (Optional)', jobDescription: ['Accomplishment 1', 'Task 1', 'Task 2'] }
        updated.push(toAdd);
        setWorkExperience(updated);
    }
    function deleteWorkExperience(id) {
        let workExperienceEditing = workExperience.findIndex(element => element.id === id);
        let updated = workExperience.slice();
        updated.splice(workExperienceEditing, 1);
        setWorkExperience(updated);
    }
    function hideEntireSection(arg) {
        setActiveFields({ ...activeFields, [arg]: false })
    }
    function addEducation() {
        let updated = education.slice();
        updated = [...education, { id: uuid(), study: '', institution: '', period: { startMonth: 'mm', startYear: 'year', endMonth: 'mm', endYear: 'year', present: false, location: '' }, aboutEducation: 'Studied/Courses', educationDescription: ['Thing Learned', 'Thing Learned 2', 'Thing Learned 3'] }]
        setEducation(updated)
    }
    function deleteEducation(id) {
        let educationEditing = education.findIndex(element => element.id === id);
        let updated = education.slice();
        updated.splice(educationEditing, 1);
        setEducation(updated);
    }
    function DeleteEducationLi(id, index) {
        let educationEditing = education.findIndex(element => element.id === id);
        let updated = education.slice()
        updated[educationEditing].educationDescription.splice(index, 1);
        setEducation(updated)
    }
    function AddEducationLi(id) {
        let educationEditing = education.findIndex(element => element.id === id);
        let updated = education.slice();
        let newItem = 'Added New Study Item'
        updated[educationEditing].educationDescription.push(newItem);
        setEducation(updated)
    }
    function addSkill() {
        let updated = skills.slice();
        updated.push('Added')
        setSkills(updated)
    }
    function deleteSkill(i) {
        let updated = skills.slice();
        updated.splice(i, 1);
        setSkills(updated)
    }
    function addInformalEdu() {
        let updated = informalEducation.slice();
        updated.push('Added Informal Edu')
        setInformalEducation(updated)
    }
    function deleteInformalEdu(i) {
        let updated = informalEducation.slice();
        updated.splice(i, 1);
        setInformalEducation(updated)
    }
    function addAchievement() {
        let updated = achievements.slice();
        updated.push({
            achievement: 'Added Achievement',
            description: 'Achievement description'
        })
        setAchievements(updated)
    }
    function deleteAchievement(i) {
        let updated = achievements.slice();
        updated.splice(i, 1);
        setAchievements(updated)
    }
    function deleteAchievementDescription(i) {
        let updated = achievements.slice();
        updated[i].description = '';
        setAchievements(updated);
    }
    function addLanguage() {
        let updated = languages.slice();
        updated.push({
            language: '',
            level: 1
        });
        setLanguages(updated);
    }
    function deleteLanguage(i) {
        let updated = languages.slice();
        updated.splice(i, 1)
        setLanguages(updated);
    }
    function download() {
        setIsEditMode(false)
        window.scrollTo(0, 0);
        let textareas = Array.from(document.getElementsByTagName('textarea'));
        textareas.forEach(textarea => {
            let newElement = document.createElement('p');
            if (textarea.className !== '') {
                newElement.classList.add(textarea.className)
            }
            newElement.innerText = textarea.value;
            textarea.parentNode.replaceChild(newElement, textarea)
        });
        var element = document.getElementById('downloadthis');
        var opt = {
            margin: [12, 0, 0, 0],
            filename: basicInfo.name,
            image: { type: 'jpeg', quality: 1, },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'letter' }
        };
        html2pdf().from(element).set(opt).save();
    }

    const context = {
        filterCategory,
        basicInfo,
        basicInfoChangeHandler,
        image,
        changeImage,
        contactInfo,
        contactInfoChangeHandler,
        workExperience,
        workExperienceChangeHandler,
        skills,
        skillsChangehandler,
        achievements,
        achievementsChangeHandler,
        education,
        educationChangeHandler,
        dataScienceSkills,
        dataScienceSkillsChangeHandler,
        languages,
        languageChangeHandler,
        informalEducation,
        informalEducationChangehandler,
        filterTips,
        filterTipsToDisplay,
        tipsToDisplay,
        download,
        editMode,
        isEditMode,
        DeleteWorkAccomplishment,
        AddWorkAccomplishmentHandler,
        DeleteAboutJob,
        addWorkExperience,
        deleteWorkExperience,
        activeFields,
        hideEntireSection,
        PdfDownloadCheck,
        fieldsFilled,
        laikaAndLinkedinChecked,
        addEducation,
        deleteEducation,
        DeleteEducationLi,
        AddEducationLi,
        addSkill,
        deleteSkill,
        addInformalEdu,
        deleteInformalEdu,
        addAchievement,
        deleteAchievement,
        deleteAchievementDescription,
        addLanguage,
        deleteLanguage
    }

    return (
        <Context.Provider value={context}>{props.children}</Context.Provider>
    )
}

export const ResumeProvider = Provider;
export const ResumeContext = Context;