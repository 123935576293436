import React, { useState, Fragment } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import RemoveButton from '../CvBuildingComponents/RemoveButton';
import AddNewButton from '../CvBuildingComponents/AddNewButton';


function EducationInput(props) {
    const [focused, setFocused] = useState(false);
    return (
        <Fragment>
            <TextareaAutosize
                onChange={props.function}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onClick={props.filtertips}
                name={props.name}
                value={props.value}
                data-tips='education'
                disabled={props.disabled}
                placeholder={props.placeholder} />
            {focused &&
                <>
                    <RemoveButton text={props.removeBtnText} function={props.remove} />
                    <AddNewButton text={props.addBtnText} function={props.add} />
                </>}
        </Fragment>
    )
}

export default EducationInput;