import React, { useContext, useMemo, useState } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import RemoveButton from './RemoveButton';
import InformalEducationInput from './InformalEducationInput';

const InformalEducation = () => {
    const { informalEducation, isEditMode, hideEntireSection } = useContext(ResumeContext);
    const [informalEduFocused, setInformalEduFocused] = useState(false);


    return useMemo(() => {
        return (
            <div className='InformalEducation'>
                <div style={{ position: 'relative' }}>
                    <input type="text" readOnly value="INFORMAL EDUCATION" className='h3' onFocus={() => setInformalEduFocused(true)} onBlur={() => setInformalEduFocused(false)} />
                    {informalEduFocused &&
                        <RemoveButton text='Delete this' function={() => hideEntireSection('informalEducation')} />
                    }
                </div>
                {informalEducation &&
                    informalEducation.map((informalEducation, i) =>
                        <InformalEducationInput
                            key={i}
                            value={informalEducation}
                            i={i}
                        />
                    )}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informalEducation, isEditMode, informalEduFocused])
}

export default InformalEducation;