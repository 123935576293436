import React, { useContext } from 'react';
import './DataScienceCV.css';
import { Row, Col } from 'react-bootstrap';
import CVimage from '../../Components/CvBuildingComponents/CVimage';
import BasicInfo from '../../Components/CvBuildingComponents/BasicInfo';
import ContactInfo from '../../Components/CvBuildingComponents/ContactInfo';
import WorkExperience from '../../Components/CvBuildingComponents/WorkExperience';
import Education from '../../Components/CvBuildingComponents/Education';
import SkillsDataScience from '../../Components/CvBuildingComponents/SkillsDataScience';
import Achievements from '../../Components/CvBuildingComponents/Achievmenets&Certificates';
import Languages from '../../Components/CvBuildingComponents/Languages';
import InformalEducation from '../../Components/CvBuildingComponents/InformalEducation';
import EditAndDownloadButtons from '../../Components/CvBuildingComponents/EditAndDownloadButtons';
import { ResumeContext } from '../../Context/ResumeContext';


const DataScienceCV = () => {
    const { activeFields } = useContext(ResumeContext);

    return (
        <div className=' CV' >
            <EditAndDownloadButtons />
            <div className='DataScienceCV' id='downloadthis'>
                <Row className=''>
                    <Col md={12}>
                        <Row>
                            <Col md={3}>
                                <CVimage />
                            </Col>
                            <Col md={9}>
                                <BasicInfo />
                            </Col>
                        </Row>
                        <hr />
                        <ContactInfo />
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ paddingLeft: '30px', marginTop: '30px' }}>
                        {activeFields.workExperience && <WorkExperience />}
                        {activeFields.education && <Education />}
                    </Col>
                    <Col md={6} style={{ marginTop: '30px' }}>
                        <SkillsDataScience />
                        {activeFields.achievements && <Achievements />}
                        <Languages />
                        {activeFields.informalEducation && <InformalEducation />}
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default DataScienceCV