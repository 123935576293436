import React from 'react';
import './Popup.css';
import Footer from '../Footer/Footer';


const Popup = ()=>{

    return(
        <div className='Popup'>
            <div className='popup-text'>
                <h1>Did you finish all <br/>three? Way to go!</h1>
                <p>Good luck on your next job! If <br/>you need help, counseling or <br/>just want to leave a suggestion, <br/>
                contact us at
                </p>
                <span>hello@wearelaika.com</span>
            </div>
            <Footer/>
        </div>
    )
}

export default Popup;