import React, { useContext, useMemo, useState } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import ContactInfoInput from './ContactInfoInput';

const ContactInfo = () => {
    const { contactInfo, isEditMode } = useContext(ResumeContext);

    const [isHidden, setIsHidden] = useState({
        mail: false,
        phone: false,
        location: false,
        twitter: false,
        linkedin: false
    })


    return useMemo(() => {
        return (

            <ul className='ContactInfo'>
                {contactInfo && <>
                    {((contactInfo.mail || isEditMode) && !isHidden.mail) &&
                        <ContactInfoInput
                            icon='envelope'
                            value={contactInfo.mail}
                            name='mail'
                            placeholder='Email'
                            removeBtnText='Remove Email'
                            removeContact={() =>setIsHidden({ ...isHidden, mail: true })}
                        />}
                    {((contactInfo.phone || isEditMode) && !isHidden.phone) &&
                        <ContactInfoInput
                            icon='mobile-alt'
                            value={contactInfo.phone}
                            name='phone'
                            placeholder='Phone Number'
                            removeBtnText='Remove Phone Number'
                            removeContact={() =>setIsHidden({ ...isHidden, phone: true })}
                        />}
                    {((contactInfo.location || isEditMode) && !isHidden.location) &&
                        <ContactInfoInput
                            icon='map-marker-alt'
                            value={contactInfo.location}
                            name='location'
                            placeholder='City, Country'
                            removeBtnText='Remove Location'
                            removeContact={() =>setIsHidden({ ...isHidden, location: true })}
                        />}
                    {((contactInfo.twitter || isEditMode) && !isHidden.twitter) &&
                        <ContactInfoInput
                            icon={['fab', 'twitter']}
                            value={contactInfo.twitter}
                            name='twitter'
                            placeholder='Twitter'
                            removeBtnText='Remove Twitter'
                            removeContact={() =>setIsHidden({ ...isHidden, twitter: true })}
                        />}
                    {((contactInfo.linkedin || isEditMode) && !isHidden.linkedin) &&
                        <ContactInfoInput
                            icon={['fab', 'linkedin']}
                            value={contactInfo.linkedin}
                            name='linkedin'
                            placeholder='Linkedin'
                            removeBtnText='Remove Linkedin'
                            removeContact={() => setIsHidden({ ...isHidden, linkedin: true })}
                        />}
                </>}
            </ul>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactInfo, isEditMode, isHidden])
}

export default ContactInfo;