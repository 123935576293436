import React, { useMemo, useState, useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';

const Period = (props) => {
    const { isEditMode } = useContext(ResumeContext)
    const [focused, setFocused] = useState(false);

    return useMemo(() => {
        return (
            <div className='period'>
                {(props.element.period.startYear || props.element.period.endYear || focused) &&
                    <span>
                        {(props.element.period.startMonth || focused === true) &&
                            <>
                                <input
                                    onChange={(e) => props.handler(e, props.element.id)}
                                    onClick={(e) => props.tipsHandler(e)}
                                    onMouseDown={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    name='startMonth'
                                    value={props.element.period.startMonth}
                                    className='month startMonth'
                                    type="text"
                                    placeholder='mm'
                                    maxLength='2'
                                    data-tips='dates'
                                    disabled={!isEditMode}
                                />
                                <span className='period-separator'>/</span> </>}
                        <input
                            onChange={(e) => props.handler(e, props.element.id)}
                            onClick={(e) => props.tipsHandler(e)}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            name='startYear'
                            value={props.element.period.startYear}
                            className='year'
                            type="text"
                            placeholder='yyyy'
                            maxLength='4'
                            data-tips='dates'
                            disabled={!isEditMode}
                        /> -
            {props.element.period.present ?
                            <input
                                onChange={(e) => props.handler(e, props.element.id)}
                                onClick={(e) => props.tipsHandler(e)}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                className='present'
                                value='Present'
                                readOnly
                                data-tips='dates'
                                disabled={!isEditMode}
                            />
                            :
                            <React.Fragment>
                                <input
                                    onChange={(e) => props.handler(e, props.element.id)}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    name='endMonth'
                                    value={props.element.period.endMonth}
                                    className='month endMonth'
                                    type="text"
                                    placeholder='mm'
                                    maxLength='2'
                                    onClick={(e) => props.tipsHandler(e)}
                                    data-tips='dates'
                                    disabled={!isEditMode}
                                />
                                <span className='period-separator'>/</span>
                                <input
                                    onChange={(e) => props.handler(e, props.element.id)}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    name='endYear'
                                    value={props.element.period.endYear}
                                    className='year'
                                    type="text"
                                    placeholder='yyyy'
                                    maxLength='4'
                                    onClick={(e) => props.tipsHandler(e)}
                                    data-tips='dates'
                                    disabled={!isEditMode}
                                />
                            </React.Fragment>
                        }
                        {focused &&
                            <React.Fragment>
                                <input
                                    id='present-checkbox'
                                    onMouseDown={(e) => props.handler(e, props.element.id)}
                                    onBlur={() => setFocused(false)}
                                    name='present'
                                    checked={props.element.period.present}
                                    type="checkbox"
                                    readOnly
                                    onClick={(e) => props.tipsHandler(e)}
                                    data-tips='dates'
                                    disabled={!isEditMode}
                                />
                                <label htmlFor="present-checkbox">Present</label>
                            </React.Fragment>
                        }
                        {<span>
                            <input
                                onChange={(e) => props.handler(e, props.element.id)}
                                name='location'
                                className='location'
                                value={props.element.period.location}
                                type="text"
                                disabled={!isEditMode}
                                placeholder='City, Country'
                            />
                        </span>}
                    </span>}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.element.period, isEditMode, focused])
}

export default React.memo(Period);