import React from 'react';
import { useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import { useMemo } from 'react';


const Tips = (props) => {
  const { tipsToDisplay } = useContext(ResumeContext);
  const tipsstyle1 = {
    margin: '8vh 50px 8vh 0',
    height: '80vh'
  };
  const tipsstyle2 = {
    margin: '23vh 50px 8vh 0',
    height: '64vh'
  };
  return useMemo(() => {
    return (
      <div style={props.margin ? tipsstyle1 : tipsstyle2} className='Tips'>
        <p>{tipsToDisplay}</p>
      </div>
    )
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipsToDisplay])
}

export default Tips;