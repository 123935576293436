import React, { useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import { Link } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';

const EditAndDownloadButtons = () => {
    const { download, editMode, isEditMode, PdfDownloadCheck, fieldsFilled, laikaAndLinkedinChecked } = useContext(ResumeContext);

    const popover1 = (
        <Popover id="popover-basic">
            <Popover.Title>
            {(!laikaAndLinkedinChecked.laika && !laikaAndLinkedinChecked.linkedin) &&
             'Check Linkedin and WeareLaika.com fields before downloading the CV'}
            </Popover.Title>
            <Popover.Content>
                {fieldsFilled <= 4 && 'Fill more sections in the Cv builder, at least 4'}
                {(fieldsFilled >= 4 && (laikaAndLinkedinChecked.laika && laikaAndLinkedinChecked.linkedin)) &&
                'Thank you for using our Cv Builder'}
          </Popover.Content>
        </Popover>
    )

    return (
        <React.Fragment>
            {!isEditMode && <button onClick={() => editMode()} className=' editCv-btn' data-html2canvas-ignore>EDIT</button>}
            <OverlayTrigger trigger="hover" placement="top" overlay={popover1}>
                <Link to='/popup' onMouseEnter={() => PdfDownloadCheck()}
                 onClick={(fieldsFilled >= 4 && (laikaAndLinkedinChecked.laika && laikaAndLinkedinChecked.linkedin)) ?
                    () => download() :  e => e.preventDefault()}
                 className=' downloadCv-btn' data-html2canvas-ignore >DOWNLOAD</Link>
            </OverlayTrigger>
        </React.Fragment>
    )
}
export default EditAndDownloadButtons;
