import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Footer from '../Footer/Footer'



const Home = () => {

    return (
        <Row className='Home'>
            <div className='vl'></div>
            <Col md={{ span: 5, offset: 1 }} className='home-text'>
                <div>
                    <h1>The Ultimate <br /> CV and Portfolio Check-List</h1>
                    <p className='intro-paragraph'>Are you a Web Developer, Data Scientist, Digital Marketer or a Designer? <br />
                        Have your CV and portfolio in check and create a 5-star representation <br /> of your skills with this guide.</p>
                    <Link className='purple-btn step-inside' to='/cv-builder/category'>Step Inside</Link>
                </div>
            </Col>
            <Col md={6} className='home-image'></Col>
            <Footer />
        </Row >
    )

}

export default Home