import React, { useContext, useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RemoveButton from './RemoveButton';
import { useLocation } from 'react-router-dom';
import { ResumeContext } from '../../Context/ResumeContext';


function ContactInfoInput(props) {
    const { contactInfoChangeHandler, filterTipsToDisplay, isEditMode } = useContext(ResumeContext);
    let developerCv = useLocation().pathname.includes('development')
    const [focused, setFocused] = useState(false);


    return (
        <li>
      {      <InputGroup>
                {!developerCv &&
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={props.icon} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>}
                <FormControl
                    onChange={(e) => contactInfoChangeHandler(e)}
                    onClick={(e) => filterTipsToDisplay(e)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    data-tips='contact'
                    disabled={!isEditMode}
                    value={props.value}
                    name={props.name}
                    placeholder={props.placeholder}
                />
                {developerCv &&
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={props.icon} />
                        </InputGroup.Text>
                    </InputGroup.Append>}
                {focused && <RemoveButton text={props.removeBtnText} function={props.removeContact}/>}
            </InputGroup>}
        </li>
    )
}

export default ContactInfoInput;