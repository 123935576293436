import React, { useState, useContext } from 'react';
import AutosizeInput from 'react-input-autosize';
import RemoveButton from './RemoveButton';
import AddNewButton from './AddNewButton';
import { ResumeContext } from '../../Context/ResumeContext';


function InformalEducationInput(props) {
    const { informalEducationChangehandler, filterTipsToDisplay, isEditMode, addInformalEdu, deleteInformalEdu } = useContext(ResumeContext);
    const [focused, setFocused] = useState(false);
    
    return (
        <span style={{ position: 'relative' }}>
            <AutosizeInput
                onChange={(e) => informalEducationChangehandler(e, props.i)}
                onClick={(e) => filterTipsToDisplay(e)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={props.value}
                type="text"
                data-tips='informalEducation'
                disabled={!isEditMode}
            />
            {focused && <>
                <AddNewButton text='Add new' function={() => addInformalEdu()} />
                <RemoveButton text='Delete this' function={() => deleteInformalEdu(props.i)} />
            </>}
        </span>
    )
}

export default InformalEducationInput;