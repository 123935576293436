import React from 'react';
import './Navmenu.css';
import {NavLink, useParams} from 'react-router-dom'
import { useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';

const Navmenu = (props) => {
    const {filterTips} = useContext(ResumeContext)

    return (
        <div className='Navmenu'>
            <NavLink to={`/cv-builder/${useParams().category}/CV`} onMouseDown={props.CVactive} onClick={(e)=> filterTips(e)}  activeClassName="chosen" data-path='CV'>CV</NavLink>
            <NavLink to={`/cv-builder/${useParams().category}/Linkedin`} onMouseDown={props.CVnotactive} onClick={(e)=> filterTips(e)} activeClassName="chosen" data-path='Linkedin'>LINKEDIN</NavLink>
            <NavLink to={`/cv-builder/${useParams().category}/Laika`} onMouseDown={props.CVnotactive} onClick={(e)=> filterTips(e)} activeClassName="chosen" data-path='Laika'>WEARELAIKA.COM</NavLink>
        </div>
    )

}

export default Navmenu;