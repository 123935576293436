import React, { useContext } from 'react';
import './Laika.css';
import Carousel from 'react-bootstrap/Carousel';
import { ResumeContext } from '../../Context/ResumeContext';

const Laika = () => {
    const { filterTipsToDisplay } = useContext(ResumeContext);

    return (
        <div className='Laika'>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/1.png`)}
                        alt="First slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='email' className='email hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='socialMedia' className='socialMedia hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/2.png`)}
                        alt="Second slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='useFor' className='useFor hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/3.png`)}
                        alt="Third slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='industry' className='industry hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='expertise' className='expertise hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='technologies' className='technologies hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='experience' className='experience hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/4.png`)}
                        alt="Fourth slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='experience' className='experience1 hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='education' className='education hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/5.png`)}
                        alt="Fifth slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='locations' className='locations hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='jobType' className='jobType hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='jobTitle' className='jobTitle hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='opportunity' className='opportunity hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require(`../../assets/images/Laika/6.png`)}
                        alt="Sixth slide"
                    />
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='experienceLevel' className='experienceLevel hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='salary' className='salary hover-tips' ></div>
                    <div onMouseEnter={(e) => filterTipsToDisplay(e)} data-tips='jobPlan' className='jobPlan hover-tips' ></div>
                </Carousel.Item>
            </Carousel>
        </div>
    )

}

export default Laika;