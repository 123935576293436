import React, { useMemo, useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import LanguageInputs from './LanguageInputs';



const Languages = () => {
    const { languages } = useContext(ResumeContext);


    return useMemo(() => {
        return (
            <div className='Languages'>
                <input type="text" readOnly value="LANGUAGES" className='h3' />
                {languages &&
                    languages.map((element, i) => (
                        <LanguageInputs
                            key={i}
                            i={i}
                            value={element}
                        />
                    ))
                }
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languages])
}

export default Languages;