import React,{ useContext }  from 'react';
import './Linkedin.css'
import Carousel from 'react-bootstrap/Carousel'
import { ResumeContext } from '../../Context/ResumeContext';

const Linkedin = () => {
    const { filterTipsToDisplay } = useContext(ResumeContext);
    return (
        <div className='Linkedin'>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src= {require(`../../assets/images/Linkedin/bill1.png`)}
                        alt="First slide"
                    />
                        <div onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='about' className='about hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src= {require(`../../assets/images/Linkedin/bill2.png`)}
                        alt="Second slide"
                    />
                        <div onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='activity' className='acivity hover-tips' ></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src= {require(`../../assets/images/Linkedin/bill3.png`)}
                        alt="Third slide"
                    />
                        <div onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='workExperience' className='workExperience hover-tips' ></div>
                        <div onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='education' className='education hover-tips' ></div>

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src= {require(`../../assets/images/Linkedin/bill4.png`)}
                        alt="Fourth slide"
                    />
                        <div onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='interests' className='interests hover-tips' ></div>
                </Carousel.Item>
            </Carousel>
        </div>
    )

}

export default Linkedin

