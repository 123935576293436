import React, { useContext } from 'react';
import './DigitalMarketingCV.css';
import { Row, Col } from 'react-bootstrap';
import CVimage from '../../Components/CvBuildingComponents/CVimage';
import BasicInfo from '../../Components/CvBuildingComponents/BasicInfo';
import ContactInfo from '../../Components/CvBuildingComponents/ContactInfo';
import WorkExperience from '../../Components/CvBuildingComponents/WorkExperience';
import Education from '../../Components/CvBuildingComponents/Education';
import Skills from '../../Components/CvBuildingComponents/Skills';
import InformalEducation from '../../Components/CvBuildingComponents/InformalEducation';
import Achievements from '../../Components/CvBuildingComponents/Achievmenets&Certificates';
import Languages from '../../Components/CvBuildingComponents/Languages';
import EditAndDownloadButtons from '../../Components/CvBuildingComponents/EditAndDownloadButtons';
import { ResumeContext } from '../../Context/ResumeContext';


const DigitalMarketingCv = () => {
    const { isEditMode, activeFields } = useContext(ResumeContext);


    return (
        <div className='CV' >
            <EditAndDownloadButtons />
            <div className='DigitalMarketingCV' id='downloadthis'>
                <Row className=''>
                    <Col md={12}>
                        <Row>
                            <Col md={3}>
                                <CVimage />
                            </Col>
                            <Col md={9}>
                                <BasicInfo />
                            </Col>
                        </Row>
                        <div className='contact-bg'>
                            <ContactInfo />
                        </div>
                        <Row>
                            <Col md={6}>
                                {activeFields.workExperience && <WorkExperience />}
                                <Education />
                            </Col>
                            <Col md={6}>
                                <Skills />
                                {activeFields.achievements && <Achievements />}
                                <Languages />
                                {(isEditMode && activeFields.informalEducation) && <InformalEducation />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default DigitalMarketingCv