import React, { useContext, useState, useMemo } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import Period from './Period';
import WorkExperienceInput from './WorkExperienceInput';
import AddNewButton from './AddNewButton';
import RemoveButton from './RemoveButton';



const WorkExperience = () => {
    const { workExperience, workExperienceChangeHandler, filterTipsToDisplay, isEditMode, addWorkExperience, hideEntireSection } = useContext(ResumeContext);
    const [titleFocused, setTitleFocused] = useState(false);
    const [aboutJobFocused, setAboutJobFocused] = useState(false);


    return useMemo(() => {
        return (
            <div className='WorkExperience'>
                <div className='main-input' style={{ position: 'relative' }}>
                    <input
                        type="text"
                        readOnly
                        value="WORK EXPERIENCE"
                        className='h3'
                        onFocus={() => setTitleFocused(true)}
                        onBlur={() => setTitleFocused(false)}
                        disabled={!isEditMode}
                    />
                    {titleFocused &&
                        <RemoveButton text='Delete entire Work Experience section. Do this only in case you dont have work experience'
                            function={() => hideEntireSection('workExperience')}
                        />
                    }
                    {titleFocused &&
                        <AddNewButton text='Add new Work Experience Field' function={() => addWorkExperience()} />}
                </div>
                {
                    workExperience &&
                    workExperience.map(element => (
                        <ul key={element.id} className='main-ul'>
                            <li className='first'>
                                <ul className='secondary-ul'>
                                    <li style={{ position: 'relative' }}>
                                        <WorkExperienceInput
                                            value={element.position}
                                            elemenetID={element.id}
                                            name='position'
                                            placeholder='Position/Title'
                                        />
                                    </li>
                                    <li>
                                        <WorkExperienceInput
                                            value={element.company}
                                            elemenetID={element.id}
                                            name='company'
                                            placeholder='Company/Workplace'
                                        />
                                    </li>
                                    <li className='date-location'>
                                        <Period
                                            element={element}
                                            handler={workExperienceChangeHandler}
                                            tipsHandler={filterTipsToDisplay}
                                        />
                                        <div style={{ position: 'relative' }}>
                                            {(element.aboutJob || aboutJobFocused) &&
                                                <WorkExperienceInput
                                                    value={element.aboutJob}
                                                    elemenetID={element.id}
                                                    name='aboutJob'
                                                    placeholder='Accomplishments/Tasks/Duties'
                                                    onFocus={() => setAboutJobFocused(true)}
                                                    onBlur={() => setAboutJobFocused(false)}
                                                />}
                                        </div>
                                    </li>
                                    <li>
                                        <ul className='accomplishments'>
                                            {element.jobDescription.map((description, i) => (
                                                <li key={i}>
                                                    <WorkExperienceInput
                                                        value={description}
                                                        id={i}
                                                        elemenetID={element.id}
                                                        name='jobDescription'
                                                        placeholder=' Write Accomplishment, Task or Duty'
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    ))
                }
            </div >
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workExperience, isEditMode, titleFocused])
}
export default WorkExperience;