import React, { useContext, useState, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ResumeContext } from '../../Context/ResumeContext';
import Period from './Period';
import RemoveButton from './RemoveButton';
import AddNewButton from './AddNewButton';
import EducationInput from './EducationInput';

const Education = () => {
    const { education, educationChangeHandler, filterTipsToDisplay, isEditMode, hideEntireSection, addEducation, deleteEducation, DeleteEducationLi, AddEducationLi } = useContext(ResumeContext);
    const [inputFocused, setInputFocused] = useState({
        main: false,
    });

    return useMemo(() => {
        return (
            <div className='Education'>
                <div className='main-input' style={{ position: 'relative' }}>
                    <input type="text" readOnly value="EDUCATION" onFocus={() => setInputFocused({ ...inputFocused, main: true })} onBlur={() => setInputFocused({ ...inputFocused, main: false })} className='h3' />
                    {inputFocused.main &&
                        <RemoveButton text=" Delete entire Education section. Do this only in case you dont have relevant Education" function={() => hideEntireSection('education')} />
                    }
                    {inputFocused.main &&
                        <AddNewButton text='Add new Education Field' function={() => addEducation()} />
                    }
                </div>
                {education &&
                    education.map(element => (
                        <ul key={element.id} >
                            <li className='first'>
                                <ul className='Edu-ul'>
                                    <li>
                                        <EducationInput
                                            function={(e) => educationChangeHandler(e, element.id)}
                                            filtertips={(e) => filterTipsToDisplay(e)}
                                            value={element.study}
                                            disabled={!isEditMode}
                                            remove={() => deleteEducation(element.id)}
                                            add={() => addEducation()}
                                            name='study'
                                            placeholder='Study program'
                                            removeBtnText='Delete this Education Field'
                                            addBtnText='Add new Education Field'
                                        />
                                    </li>
                                    <li><TextareaAutosize onChange={(e) => educationChangeHandler(e, element.id)} name='institution' value={element.institution} type="text" className='institution' onClick={(e) => filterTipsToDisplay(e)} data-tips='education' disabled={!isEditMode} placeholder='Institution' /></li>
                                    <li className='date-location'>
                                        <Period
                                            element={element}
                                            handler={educationChangeHandler}
                                            tipsHandler={filterTipsToDisplay}
                                            disabled={!isEditMode}
                                            placeholder=''
                                        />
                                        {element.aboutEducation && <input onChange={(e) => educationChangeHandler(e, element.id)} name='aboutEducation' value={element.aboutEducation} onClick={(e) => filterTipsToDisplay(e)} data-tips='education' disabled={!isEditMode} placeholder='Studied/Courses' />}
                                    </li>
                                    <li>
                                        <ul className='accomplishments'>
                                            {element.educationDescription &&
                                                element.educationDescription.map((description, i) => (
                                                    <li key={i}>
                                                        <EducationInput
                                                            function={(e) => educationChangeHandler(e, element.id, i)}
                                                            filtertips={(e) => filterTipsToDisplay(e)}
                                                            value={description}
                                                            disabled={!isEditMode}
                                                            remove={() => DeleteEducationLi(element.id, i)}
                                                            add={() => AddEducationLi(element.id)}
                                                            name='educationDescription'
                                                            placeholder='What you studied'
                                                            removeBtnText='Delete this'
                                                            addBtnText='Add new item'
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    ))
                }
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [education, inputFocused, isEditMode])
}

export default Education;