import React, { useContext, useMemo, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ResumeContext } from '../../Context/ResumeContext';
import RemoveButton from './RemoveButton';

const BasicInfo = (props) => {
    const { basicInfo, basicInfoChangeHandler, filterTipsToDisplay, isEditMode } = useContext(ResumeContext);
    const [aboutMeFocused, setAboutMeFocused] = useState(false);
    const [aboutMeHidden, setAboutMeHidden] = useState(false)

    return useMemo(() => {
        return (
            <ul className='BasicInfo'>
                <li className='first'>
                    {basicInfo &&
                        <ul>
                            <li className='name'>
                                <TextareaAutosize
                                    onChange={(e) => basicInfoChangeHandler(e)}
                                    name='name'
                                    placeholder='Full Name'
                                    value={basicInfo.name}
                                    type="text"
                                    disabled={!isEditMode}
                                />
                            </li>
                            <li className='titles'>
                                <TextareaAutosize
                                    onChange={(e) => basicInfoChangeHandler(e)}
                                    name='titles'
                                    placeholder='Professional title'
                                    value={basicInfo.titles}
                                    type="text"
                                    disabled={!isEditMode}
                                />
                            </li>
{!aboutMeHidden    &&          <li className='goals'>
                                <TextareaAutosize
                                    onChange={(e) => basicInfoChangeHandler(e)}
                                    onClick={(e) => filterTipsToDisplay(e)}
                                    onFocus={()=> setAboutMeFocused(true)}
                                    onBlur={()=> setAboutMeFocused(false)}
                                    placeholder='About me'
                                    data-tips='aboutMe'
                                    name='goals'
                                    type="text"
                                    value={basicInfo.goals}
                                    disabled={!isEditMode}
                                />
                                {aboutMeFocused &&
                                 <RemoveButton function={()=> setAboutMeHidden(true)} text='Delete About Me field'/>}
                            </li>}
                        </ul>}
                </li>
            </ul>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basicInfo, isEditMode, aboutMeFocused])
}

export default BasicInfo;