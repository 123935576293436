import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <div className='Footer'>
            <p>Created with {`<3`} by the <a target='_blank' rel="noopener noreferrer" href='http://codepreneurs.co/'> Brainster Coding Academy </a>
              students and <a target='_blank' rel="noopener noreferrer" href='https://www.wearelaika.com/'>wearelaika.com</a></p>
        </div>
    )

}

export default Footer