import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




function Interests() {
    return (
        <React.Fragment>
            <h3>Interests</h3>
            <ul className='Interests'>
                <li>
                    <span> <FontAwesomeIcon icon='atom' /></span>
                    <span>Physics</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='fan' /></span>
                    <span>Alternative Energy</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='mountain' /></span>
                    <span>Sustainability</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='rocket' /></span>
                    <span>Space Engineering</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='globe-europe' /></span>
                    <span>Philantropy</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='book' /></span>
                    <span>Reading</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon={['fab', 'twitter']} /></span>
                    <span>Twitter</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='gamepad' /></span>
                    <span>Video Games</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='space-shuttle' /></span>
                    <span>Extraterrestrial Life</span>
                </li>
                <li>
                    <span> <FontAwesomeIcon icon='microchip' /></span>
                    <span>AI</span>
                </li>

            </ul>
        </React.Fragment>
    )
}

export default Interests;