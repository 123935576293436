import React, { useContext, useMemo } from 'react';
import Image from 'react-bootstrap/Image';
import { ResumeContext } from '../../Context/ResumeContext';

const CVimage = () => {
  const { image, changeImage, filterTipsToDisplay, isEditMode } = useContext(ResumeContext)

 return useMemo(() => {
    return (
      <div className="CVimage">
        <label htmlFor="attach-image" >
          {image && <Image src={image} roundedCircle onMouseEnter={(e)=>filterTipsToDisplay(e)} data-tips='image'/>}
        </label>
        <input id="attach-image" type="file" onChange={(e) => changeImage(e)} disabled={!isEditMode}/>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, isEditMode])
}

export default CVimage;