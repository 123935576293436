import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faMobileAlt, faMapMarkerAlt, faCircle, faTimes, faPlusSquare, faAtom, faFan,
   faMountain, faRocket, faGlobeEurope, faBook, faGamepad, faSpaceShuttle, faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Home from './Components/Home/Home';
import ChooseCategory from './Components/ChooseCategory/ChooseCategory';
import ResumeRoutes from './Components/ResumeRoutes/ResumeRoutes';
import Popup from './Components/Popup/Popup';
import { ResumeProvider } from './Context/ResumeContext';

library.add(faEnvelope, faMobileAlt, faMapMarkerAlt, faTwitter, faCircle, faTimes, faPlusSquare, faLinkedin, faAtom, faFan,
   faMountain, faRocket, faGlobeEurope, faBook, faGamepad, faSpaceShuttle, faMicrochip);





function App() {
  return (
    <ResumeProvider>
      <Container fluid className="App">
        <Router>
          <Redirect to='/cv-builder' />
          <Switch>
            <Route path='/cv-builder' exact component={Home} />
            <Route path='/cv-builder/category' render={(props) => <ChooseCategory />} />
            <Route path='/cv-builder/:category/' component={ResumeRoutes} />
            <Route path='/popup' exact component={Popup} />
          </Switch>
        </Router>
      </Container>
    </ResumeProvider>
  );
}

export default App;
