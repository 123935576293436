import React from 'react';
import './DesignerCV.css'
import { Row, Col } from 'react-bootstrap';
import BasicInfo from '../../Components/CvBuildingComponents/BasicInfo';
import WorkExperience from '../../Components/CvBuildingComponents/WorkExperience';
import ContactInfo from '../../Components/CvBuildingComponents/ContactInfo';
import Education from '../../Components/CvBuildingComponents/Education';
import Skills from '../../Components/CvBuildingComponents/Skills';
import EditAndDownloadButtons from '../../Components/CvBuildingComponents/EditAndDownloadButtons';

const DesignerCV = () => {

    return (
        <div className=' CV' >
            <EditAndDownloadButtons />
            <div className='DesignerCV' id='downloadthis'>
                <Row>
                    <Col md={{ span: 9, offset: 1 }} className='designer-content'>
                        <BasicInfo />
                        <Row >
                            <Col md={6} className='designer-cv-left'>
                                <WorkExperience />
                                <ContactInfo />
                            </Col>
                            <Col md={6} className='designer-cv-right'>
                                <Education />
                                <Skills />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default DesignerCV