import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Navmenu from '../Navmenu/Navmenu';
import Linkedin from '../Linkedin/Linkedin';
import Laika from '../Laika/Laika';
import WebDeveloperCV from '../../Containers/WebDeveloperCV/WebDeveloperCV';
import DataScienceCV from '../../Containers/DataScienceCV/DataScienceCV';
import DigitalMarketingCV from '../../Containers/DigitalMarketingCV/DigitalMarketingCV';
import DesignerCV from '../../Containers/DesignerCV/DesignerCV';
import Tips from '../Tips/Tips';
import FooterBanner from '../FooterBanner/FooterBanner';


const ResumeRoutes = () => {
    const [CVactive, setActive] = useState(true);

    return (
        <Row className='ResumeRoutes'>
            <div className='vl'></div>
            <Col md={8}>
                <Navmenu CVactive={() => setActive(true)} CVnotactive={() => setActive(false)} />
                <Switch>
                    <Route path='/cv-builder/Web-development/CV' exact component={WebDeveloperCV} />
                    <Route path='/cv-builder/Data-Science/CV' exact component={DataScienceCV} />
                    <Route path='/cv-builder/Digital-Marketing/CV' exact component={DigitalMarketingCV} />
                    <Route path='/cv-builder/Design/CV' exact component={DesignerCV} />
                    <Route path='/cv-builder/:category/LinkedIn' exact component={Linkedin} />
                    <Route path='/cv-builder/:category/Laika' exact component={Laika} />
                </Switch>
            </Col>
            <Col md={4}>
                <Tips margin={CVactive} />
            </Col>
            <FooterBanner />
        </Row>
    )
}

export default ResumeRoutes;