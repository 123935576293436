import React, { useState, useContext } from 'react';
import { ResumeContext } from '../../Context/ResumeContext';
import AddNewButton from './AddNewButton';
import RemoveButton from './RemoveButton';


function LanguageInputs(props) {
    const { languageChangeHandler, filterTipsToDisplay, isEditMode, addLanguage, deleteLanguage } = useContext(ResumeContext);
    const [focused, setFocused] = useState(false);


    return (
        <div>
            <div style={{ position: 'relative' }}>
                <input
                    type="text"
                    value={props.value.language}
                    onChange={(e) => languageChangeHandler(e, props.i)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    name='language'
                    onClick={(e) => filterTipsToDisplay(e)}
                    data-tips='language'
                    disabled={!isEditMode}
                    placeholder='Language' />
                {focused && <>
                    <AddNewButton text=' Add new language' function={() => addLanguage()} />
                    <RemoveButton text='Delete this language' function={() => deleteLanguage(props.i)} />
                </>}
            </div>
            {!focused ?
                <input
                    type="text"
                    readOnly
                    onFocus={() => setFocused(true)}
                    className='language-level'
                    onMouseDown={(e) => filterTipsToDisplay(e)}
                    data-tips='language'
                    disabled={!isEditMode}
                    value={props.value.level === 1 ? 'Elementary Proficiency'
                        : props.value.level === 2 ? 'Limited Working Proficiency'
                            : props.value.level === 3 ? 'Professional Working Proficiency'
                                : props.value.level === 4 ? 'Full Professional Proficiency'
                                    : 'Native or Bilingual Proficiency'
                    }
                />
                : <div>
                    <span onMouseDown={(e) => languageChangeHandler(e, props.i)} className='1' onClick={() => setFocused(false)}>1/5</span>
                    <span onMouseDown={(e) => languageChangeHandler(e, props.i)} className='2' onClick={() => setFocused(false)}>2/5</span>
                    <span onMouseDown={(e) => languageChangeHandler(e, props.i)} className='3' onClick={() => setFocused(false)}>3/5</span>
                    <span onMouseDown={(e) => languageChangeHandler(e, props.i)} className='4' onClick={() => setFocused(false)}>4/5</span>
                    <span onMouseDown={(e) => languageChangeHandler(e, props.i)} className='5' onClick={() => setFocused(false)}>5/5</span>
                </div>
            }
        </div>
    )
}

export default LanguageInputs;