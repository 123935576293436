import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RemoveButton = (props) => {

    const popover2 = (
        <Popover id="popover-basic">
            <Popover.Content>
                {props.text}
          </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="hover" placement="top" overlay={popover2}>
            <FontAwesomeIcon icon='times' onMouseDown={props.function} />
        </OverlayTrigger>
    )
}

export default RemoveButton