import React, { useContext } from 'react';
import './ChooseCategory.css'
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FooterBanner from '../FooterBanner/FooterBanner';
import { ResumeContext } from '../../Context/ResumeContext';

const ChooseCategory = (props) => {
    const { filterCategory } = useContext(ResumeContext)
    const categories = ['Web development', 'Data Science', 'Digital Marketing', 'Design'];

    return (
        <Row className='Category'>
            <div className='vl'></div>
            <Col md={12}>
                <div className='category-cards-holder'>
                    <h1>Choose your category</h1>
                    <Row>
                        {categories.map((category, index) => (
                            <Col md={3} key={index}>
                                <div className='category-cards'>
                                    <h3>{category}</h3>
                                    <Link onClick={(e) => filterCategory(e)} className='purple-btn' to={`/cv-builder/${category.replace(' ', '-')}`} id={category}>CHOOSE</Link>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Col>
            <FooterBanner />
        </Row>
    )
}

export default ChooseCategory;