import React from 'react';
import './FooterBanner.css'

const FooterBanner = (props) => {


    return (
        <div className='FooterBanner'>
            <div className='visit-brainster'><p>Do you want to learn hands-on digital skills? </p> <a target='_blank' rel="noopener noreferrer" href="https://brainster.co/">VISIT BRAINSTER</a></div>
            <div className='visit-laika'><p>Do you want to receive job offers by IT companies?</p> <a target='_blank' rel="noopener noreferrer" href="https://www.wearelaika.com/">VISIT LAIKA</a></div>
        </div>
    )

}

export default FooterBanner;