import React, { useState, useContext  } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ResumeContext } from '../../Context/ResumeContext';
import RemoveButton from './RemoveButton';
import AddNewButton from './AddNewButton';


const WorkExperienceInput = (props) => {
    const { workExperienceChangeHandler, filterTipsToDisplay, isEditMode, DeleteWorkAccomplishment, AddWorkAccomplishmentHandler, DeleteAboutJob, addWorkExperience, deleteWorkExperience } = useContext(ResumeContext);
    const [focused, setFocused] = useState(false);
    const [target, setTarget] = useState();

    function showIcons(e) {
        setFocused(true);
        setTarget(e.target.name);
    }
    return (
        <React.Fragment>
            <TextareaAutosize
                onChange={(e) => workExperienceChangeHandler(e, props.elemenetID)}
                onClick={(e) => filterTipsToDisplay(e)}
                type="text"
                data-tips='workExperience'
                disabled={!isEditMode}
                name={props.name}
                value={props.value}
                className={props.name}
                placeholder={props.placeholder}
                id={props.id}
                onFocus={(e) => showIcons(e)}
                onBlur={() => setFocused(false)}
            />
            {(focused && target === 'jobDescription') ?
                <RemoveButton function={() => DeleteWorkAccomplishment(props.elemenetID, props.id)} text='Delete this task'/>
                : (focused && target === 'aboutJob') ?
                    <RemoveButton function={() => DeleteAboutJob(props.elemenetID)} text= 'Delete this descriptions title'/>
                    : (focused && target === 'position') ?
                        <RemoveButton function={() => deleteWorkExperience(props.elemenetID)} text='Delete this Work Experience Field'/>
                        : null}
            {(focused && target === 'jobDescription') ?
                <AddNewButton function={() => AddWorkAccomplishmentHandler(props.elemenetID)} text='Add another task'/>
                : (focused && target === 'position') ?
                    <AddNewButton function={() => addWorkExperience()} text='Add another Work Experience Field'/>
                    : null}
        </React.Fragment>
    )
}

export default WorkExperienceInput;